<template>
  <sale code="OTH"></sale>
</template>

<script lang="ts">
import Sale from '@accessory/pages/stock/out/sale.vue'

export default factory({
  components: { Sale },
  props: {
    code: {
      type: String as PropType<'SAL' | 'SALR' | 'OTH'>,
      default: 'OTH'
    }
  }
})
</script>
